.link-underline {
  text-decoration: underline;
  cursor: pointer;
  display: inline-flex;
  color: rgba(255, 255, 255, 0.6);
}

.link-underline:hover {
  color: rgba(255, 255, 255);
}
